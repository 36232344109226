<template>
  <div class="errcontent">
    <m-empty title="Sorry, page not found."></m-empty>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
export default {
  components: {
    mEmpty
  }
}
</script>

<style lang="scss" scoped>

</style>
